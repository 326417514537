import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-white ">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        ></div>

        {/* Desktop */}
        <div className="border-t bg-green-50 md:block hidden">
          <div className="container mx-auto px-4 md:pt-16 pt-16">
            <div className="w-full lg:w-12/12 px-4">
              <div className="flex flex-wrap">
                <div className="lg:w-5/12 md:w-5/12  md:border-r-1 border-black">
                  <div className="lg:w-6/12 md:w-6/12 xl:w-6/12">
                    <h4 className="text-3xl font-semibold">
                      <Link to="/index">
                        <img
                          className="w-24"
                          src={require("assets/img/logoagg.png").default}
                        ></img>
                      </Link>
                    </h4>
                    <div className="w-full flex justify-center fff text-sm mt-4 lg:w-12/12 md:w-12/12 xl:w-12/12 ">
                      <div className=" lg:w-4/12 md:w-4/12 xl:w-4/12">
                        <a href="/about">
                          <h1 className="fff pl-2 ">About Us</h1>
                        </a>
                      </div>
                      <div className="lg:w-4/12 md:w-4/12 xl:w-4/12  ">
                        <a href="/contact">
                          <h1 className="border-r-1 border-l border-black pl-1 fff">
                            Contact Us
                          </h1>
                        </a>
                      </div>
                      <div className="lg:w-4/12 pl-2 md:w-4/12 xl:w-4/12">
                        <a href="/career">Careers</a>
                      </div>
                    </div>

                    <div className="mt-4 lg:mb-0 mb-6">
                      <a
                        href="https://www.facebook.com/profile.php?id=100084916690562"
                        target="_blank"
                      >
                        <button
                          className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                          type="button"
                        >
                          <i className="fab fa-facebook-square"></i>
                        </button>
                      </a>

                      <a
                        href="https://www.instagram.com/agrizy.in/"
                        target="_blank"
                      >
                        <button
                          className="bg-white text-red-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                          type="button"
                        >
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>

                      <a
                        href="https://www.linkedin.com/company/agrizy/"
                        target="_blank"
                      >
                        <button
                          className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                          type="button"
                        >
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>

                      <a href="https://twitter.com/agrizy_in" target="_blank">
                        <button
                          className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                          type="button"
                        >
                          <i className="fab fa-twitter"></i>
                        </button>
                      </a>

                      <a
                        href="https://www.youtube.com/channel/UCI9F2BIIC6phYowUYKbRyzw"
                        target="_blank"
                      >
                        <button
                          className="bg-white text-red-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                          type="button"
                        >
                          <i className="fab fa-youtube"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap lg:w-7/12 md:w-7/12 md:px-12 px-2 mt-4">
                  <div className="lg:w-4/12 md:w-4/12 xl:w-4/12">
                    <span className="block uppercase text-black text-sm font-semibold mb-2">
                      Certifications
                    </span>
                    <ul className="list-unstyled mt-4">
                      <li>
                        <div className="text-blueGray-600 hover:text-blueGray-800 font-normal block pb-2 text-sm">
                          FSSAI
                        </div>
                      </li>
                      <li>
                        <div className="text-blueGray-600 hover:text-blueGray-800 font-normal block pb-2 text-sm">
                          ISO 22000:2018
                        </div>
                      </li>
                      <li>
                        <div className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm">
                          Kosher Certified
                        </div>
                      </li>
                      <li>
                        <div className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm">
                          Halal
                        </div>
                      </li>
                      <li>
                        <div className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm">
                          FSSC 22000
                        </div>
                      </li>
                      <li>
                        <div className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm">
                          APEDA
                        </div>
                      </li>
                      <li>
                        <div className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm">
                          SPICES BOARD INDIA
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="lg:w-4/12 md:w-4/12 xl:w-4/12 px-2 md:mt-0 mt-4">
                    <span className="block uppercase text-black text-sm font-semibold  mb-2">
                      Policies
                    </span>
                    <ul className="list-unstyled mt-2">
                      <li>
                        <a
                          className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm"
                          href="/privacy"
                        >
                          Privacy & Policy
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm"
                          href="/terms"
                        >
                          Terms & Conditions
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm"
                          href="/account-deletion"
                        >
                          Account Deletion
                        </a>
                      </li>
                    </ul>
                    <br />

                    {/*  <span className="block uppercase text-lightBlue-600 text-sm font-semibold pt-6 mb-2">
                      REQUEST FOR PROPOSAL
                    </span>
                    <div
                      className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm"
                    >
                      <a href="http://bit.ly/3PJEqhX" target="blank">
                        Applications invited for short-term research project. Click here to know more.
                      </a>
                    </div>*/}
                  </div>
                  <div className="lg:w-4/12 md:w-4/12 xl:w-4/12 md:mt-0 mt-4">
                    <span className="block uppercase text-black text-sm font-semibold  mb-2">
                      Partnerships
                    </span>
                    <ul className="list-unstyled mt-2">
                      <li>
                        <a href="/contact">
                          <div className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm">
                            Sell your Products
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <div className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm">
                            Buy from Agrizy
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <div className="text-blueGray-600 hover:text-blueGray-800 font-normal  block pb-2 text-sm">
                            Get Job Work
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center pb-6">
              <div className="text-sm text-blueGray-500 font-semibold py-1 ">
                Copyright © {new Date().getFullYear()}{" "}
                <a href="https://agrizy.in/" className="text-blueshade">
                  Agrizy
                </a>
                .{" "}
                <a
                  href="index"
                  className="text-blueGray-500 hover:text-blueGray-800"
                >
                  All Rights Reserved
                </a>
                .
              </div>
            </div>
          </div>
        </div>

        {/* Mobile View*/}
        <div className="border-t bg-green-50 pt-4 md:hidden block">
          <div className="container mx-auto pb-4">
            <div className="lg:w-6/12 md:w-6/12 xl:w-6/12 text-center">
              <h4 className="text-3xl font-semibold web">
                <Link to="/index">
                  <img
                    className="w-24"
                    src={require("assets/img/logoagg.png").default}
                  ></img>
                </Link>
              </h4>
              <div className="w-full flex justify-center fff text-sm mt-4 lg:w-12/12 md:w-12/12 xl:w-12/12 ">
                <div className=" lg:w-4/12 md:w-4/12 xl:w-4/12">
                  <a href="/about">
                    <h1 className="fff px-2">About Us</h1>
                  </a>
                </div>
                <div className="lg:w-4/12 md:w-4/12 xl:w-4/12  ">
                  <a href="/contact">
                    <h1 className="border-r-1 border-l border-black px-2 fff">
                      Contact Us
                    </h1>
                  </a>
                </div>
                <div className="lg:w-4/12 pl-2 md:w-4/12 xl:w-4/12">
                  <a href="/career">Careers</a>
                </div>
              </div>

              <div className="mt-4 lg:mb-0 mb-6">
                <a
                  href="https://www.facebook.com/profile.php?id=100084916690562"
                  target="_blank"
                >
                  <button
                    className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-facebook-square"></i>
                  </button>
                </a>

                <a href="https://www.instagram.com/agrizy.in/" target="_blank">
                  <button
                    className="bg-white text-red-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-instagram"></i>
                  </button>
                </a>

                <a
                  href="https://www.linkedin.com/company/agrizy/"
                  target="_blank"
                >
                  <button
                    className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-linkedin"></i>
                  </button>
                </a>

                <a href="https://twitter.com/agrizy_in" target="_blank">
                  <button
                    className="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-twitter"></i>
                  </button>
                </a>

                <a
                  href="https://www.youtube.com/channel/UCI9F2BIIC6phYowUYKbRyzw"
                  target="_blank"
                >
                  <button
                    className="bg-white text-red-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                    type="button"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                </a>
              </div>
            </div>
            <div className="w-full lg:w-12/12 ">
              <div className="flex flex-wrap justify-center">
                <div class="accordion">
                  <button class="accordion__button">
                    <p class="accordion__heading">Policies</p>
                    {/* <div class="icon"></div> */}
                  </button>
                  <p class="accordion__text">
                    <Link to="/privacy">Privacy Policy</Link>
                    <br/>
                    <Link to="/terms">Terms & Conditions</Link>                  
                  </p>
                </div>
                <div class="accordion">
                  <button class="accordion__button">
                    <p class="accordion__heading">Certifications</p>
                    {/* <div class="icon"></div> */}
                  </button>
                  <p class="accordion__text">
                    <Link to="/terms">Fssai</Link>
                    <br></br>
                    <Link to="">ISO 22000:2018</Link>
                    <br></br>
                    <Link to="/">Kosher Certified</Link>
                    <br></br>
                    <Link to="">Halal</Link>
                    <br></br>
                    <Link to="">FSSC 22000</Link>
                    <br></br>
                    <Link to="">APEDA</Link>
                    <br></br>
                    <Link to="">SPICES BOARD INDIA</Link>
                  </p>
                </div>

                <div class="accordion">
                  <button class="accordion__button ">
                    <p class="accordion__heading">Partnerships</p>
                    {/* <div class="icon"></div> */}
                  </button>
                  <p class="accordion__text">
                    <Link to="/contact">Sell Your Products</Link>

                    <br></br>

                    <Link to="/contact">Buy from Agrizy</Link>

                    <br></br>

                    <Link to="/contact">Get job Work</Link>
                  </p>
                </div>

                <hr className=" border-blueGray-300 " />
                <div className="flex flex-wrap items-center md:justify-between justify-center">
                  <div className="w-full md:w-4/12 px-4 mx-auto text-centerp  border-t">
                    <div className="text-sm text-blueGray-500 font-normal pt-2">
                      Copyright © {new Date().getFullYear()}{" "}
                      <a href="https://agrizy.in/" className="text-blueshade">
                        Agrizy
                      </a>
                      .{" "}
                      <a
                        href=""
                        className="text-blueGray-500 hover:text-blueGray-800"
                      >
                        All Rights Reserved
                      </a>
                      .
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
